@use 'sass:map';
@use '@angular/material' as mat;
@import '../../../../styles/ops-palette';

@mixin theme($theme) {

  .legend-wrapper {
    pointer-events: auto;

    mat-icon {
      height: 20px;
      width: 20px;
      font-size: 18px;
    }

    .legend {
      padding: 10px 15px;
      background-color: $ops-color-layer-over;
      border-radius: 10px;

      .legend-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: smaller;
        font-weight: 500;
        line-height: 24px;
        border-radius: 8px 8px 0 0;

        .configure-button {
          height: 24px;
          width: 24px;
          padding: 3px 2px;
        }
      }

      .legend-subtitle {
        font-size: x-small;
        color: #5f6368;
      }

      .legend-settings {
        display: flex;
        flex-direction: column;
        font-size: smaller;

        mat-slider {
          // width: 100%;
        }
      }

      .legend-line {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 5px;

        .legend-item__wrapper {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-size: 10px;
          gap: 10px;

          .legend-item__box {
            height: 16px;
            width: 16px;
            border-radius: 8px;
          }
          .legend-item__name {
            white-space: nowrap;
            color: #686868;
          }

        }
      }
    }
  }
}
