@use 'sass:map';
@use '@angular/material' as mat;
@import './ops-palette';

@mixin theme($theme) {

  .setup-page-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px 20px;

    .header-container {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 50px;

      h2 {
        margin: 0;
      }

      .header-container-filters {
        flex-grow: 1;
      }

      .header-container-buttons {

      }
    }

    .content-container {
      display: flex;
      height: calc(100% - 55px); // mystery
      gap: 20px;

      .selector-container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: calc(100% - 15px);
        width: 280px;
        max-width: 280px;
        min-width: 280px;
        overflow-y: auto;
        margin-top: 15px;
        padding-right: 5px; // padding to make space left of vertical scrollbar

        .selector-container-list {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2px;
        }
      }

      .divider {
        width: 2px;
        min-width: 2px;
        background-color: $ops-color-layer-over;
      }

      .form-container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
        min-width: 600px;
        overflow-y: auto;
        padding-right: 5px; // padding to make space left of vertical scrollbar
      }

      .selection-container {
        height: 100%;
        width: 100%;
      }

      .map-preview-container {
        height: 100%;
        width: 100%;

        .map {
          border-radius: 10px;
          height: calc(100vh - 130px);
          /*width: 100%;*/
        }
      }

      .map-preview-container-with-header {
        height: calc(100% - 15px);
        width: 100%;
        margin-top: 0;

        .map-header {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          row-gap: 0;
          column-gap: 20px;
          padding-left: 20px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          height: 58px;
          background-color: $ops-color-layer-over-highlight;
          white-space: nowrap;
          flex-wrap: wrap;
        }

        .map {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          height: calc(100vh - 200px);
          /*width: 100%;*/
        }
      }

      .public-portal-preview-container {
        height: 100%;
        width: 100%;

        iframe {
          border-radius: 10px;
          border: 1px solid $ops-color-layer-over-hover;
        }
      }
    }
  }

  .address-lookup-container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;

    .map-preview-container {
      height: 100%;
      width: 100%;

      .map {
        border-radius: 10px;
        height: 100%;
        /*width: 100%;*/
      }
    }
  }

  .shift-map-container {

    .map-preview-container {
      position: absolute;
      height: calc(100% - 10px);
      width: calc(100% - 300px);
      left: 300px;

      .map {
        // border-radius: 10px;
        height: 100%;
      }
    }
  }

  .report-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    margin-bottom: 10px;

    .report-name-and-desc {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 8px;

      .report-name {
        font-size: 26px;
        font-weight: 500;
      }

      .report-description {
        font-size: 16px;
      }
    }

    .report-menu__filters {
      flex-grow: 1;
    }

    .report-menu__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .download-button {
        display: flex;
        align-items: center;
      }
    }
  }

  // vehicle detail, route detail, live address
  .narrow-panel-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px 0;

    .narrow-panel-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 15px;

      .content-title-1 {
        // margin: 20px 0 10px 0;
        font-size: 20px;
        font-weight: 500;
        width: 280px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.visible-settings-button {
          width: 240px;
        }
      }
    }

    .narrow-panel-section {
      margin: 0 15px;

      .content-title-1 {
        // margin: 20px 0 10px 0;
        font-size: 20px;
        font-weight: 500;
      }

      .content-title-2 {
        margin: 10px 0 10px 0;
        font-size: 18px;
        font-weight: 400;
      }

      .content-title-3 {
        margin: 10px 0 10px 0;
        font-size: 14px;
        font-weight: 400;
      }

      .space-between {
        display: flex;
        justify-content: space-between;
      }

      .content-paragraph {
        padding: 10px 0;
        font-size: 12px;

        .content-row {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;

          .info-name {
            .mat-icon {
              &.mirrored {
                transform: scaleX(-1);
              }
            }
          }

          .info-metric {
            width: 50px;
            text-align: center;
            font-size: 16px;
          }

          .info-value {
            color: #5d5d5d;
          }
        }

        .space-between-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          pointer-events: auto;
        }
      }
    }

    .narrow-panel-divider {
      width: 100%;
      height: 1px;
      background-color: $ops-color-layer-over-hover;
    }

  }
}
