@use 'sass:map';
@use '@angular/material' as mat;
@import '../../../../styles/ops-palette.scss';

@mixin theme($theme) {

  .filter-selector-wrapper {

    .active-layers {

     .active-layer {
       background-color: $ops-color-light-background;
     }
    }

    .layers__button {
      color: black;
    }

    .layers__box {

      .layers__buttons {

        .map__filter__wrapper {

          .map__filter__square {
            &.selected {
              border-color: $ops-color-accent !important;
            }
            &.no__background {
              background-color: $ops-color-light-background;
            }
            &.configure {
              background-color: #f5f5f5;
            }
          }
        }
      }
    }
  }
}
