@use 'sass:map';
@use '@angular/material' as mat;
@import '../../../../styles/ops-palette.scss';

@mixin theme($theme) {
  .card-menu-item {
    border-color: $ops-color-layer-over-hover;

    .card-body {
      color: $ops-color-font-tertiary;
    }

    .card-footer {
      color: $ops-color-font-secondary;
    }
  }
}
