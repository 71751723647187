@use "@angular/material" as material;

// how to define this in M3?
$ops-typography-config: material.m2-define-typography-config(
  $font-family: '"Roboto", sans-serif',
  $headline-1: material.m2-define-typography-level(100px, 100px, 400, $letter-spacing: -0.05em),
  $headline-2: material.m2-define-typography-level(28px, 36px, 300, $letter-spacing: -0.05em),
  $headline-3: material.m2-define-typography-level(24px, 36px, 400, $letter-spacing: -0.05em),
  $headline-4: material.m2-define-typography-level(20px, 26px, 400, $letter-spacing: 0em),
  $headline-5: material.m2-define-typography-level(18px, 24px, 400, $letter-spacing: 0em),
  $headline-6: material.m2-define-typography-level(14px, 20px, 450, $letter-spacing: 0em),
  $subtitle-2: material.m2-define-typography-level(16px, 19px, 400, $letter-spacing: 0em),
  $subtitle-1: material.m2-define-typography-level(14px, 19px, 400, $letter-spacing: 0em),
  $body-2: material.m2-define-typography-level(14px, 23px, 400, $letter-spacing: 0em),
  $body-1: material.m2-define-typography-level(12px, 19px, 400, $letter-spacing: 0em),
  $caption: material.m2-define-typography-level(10px, 19px, 400, $letter-spacing: 0em),
  $button: material.m2-define-typography-level(14px, 19px, 400, $letter-spacing: 0em),
);

/*
.mat-headline-1 	                            headline-1 	      None
.mat-headline-2 	                            headline-2 	      None
.mat-headline-3 	                            headline-3 	      None
.mat-headline-4 	                            headline-4 	      None
.mat-h1           .mat-headline-5               headline-5 	      <h1>
.mat-h2           .mat-headline-6               headline-6 	      <h2>
.mat-h3           .mat-subtitle-1               subtitle-1 	      <h3>
.mat-h4           .mat-body-1 	                body-1 	          <h4>
.mat-body         .mat-body-2 	                body-2 	          Body text
.mat-body-strong  .mat-subtitle-2               subtitle-2 	      None
.mat-small        .mat-caption                  caption 	      None
 */
