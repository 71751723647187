@use './ops-theme.scss';
@import './ops-palette.scss';
@import './helpers.scss';
@import './forms.scss';

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.mat-mdc-list-item {
  &.flex-row .mat-mdc-list-item-unscoped-content, .mat-mdc-list-item-meta {
    display: flex;
  }
}

mat-sidenav[style] {
  visibility: visible !important;
}

.mat-drawer {
  transform: none !important;
}

.drag-scroll-content {
  overflow: hidden !important;
}

.toast-panel {
  margin-bottom: 0 !important;
  bottom: 20px !important;
  position: relative !important;
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.dialog-content {
  overflow: visible !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
  width: 10px;
}

::-webkit-scrollbar:horizontal {
  height: 5px;
}

app-settings {
  td button,
  td button .mat-icon,
  td button .mat-mdc-icon-button,
  td button .mat-mdc-button-touch-target {
    --mdc-icon-button-state-layer-size: 28px !important;
    height: 28px !important;
    width: 28px !important;
    line-height: 28px !important;
    padding: 0 !important;
    overflow: inherit;
  }

  .mat-sort-header-arrow[style] {
    .mat-sort-header-stem, .mat-sort-header-pointer-left, .mat-sort-header-pointer-right, .mat-sort-header-pointer-middle {
      display: none; // Assuming hiding stem globally doesn't cause issues
    }

    .mat-sort-header-indicator {
      opacity: 1;
      color: black;
      font-weight: bold;
      position: relative; // To position ::before pseudo-elements

      &::before {
        content: "\25b4"; // Default arrow, can be overridden
        top: -0.6em;
        position: absolute;
        visibility: visible; // Make sure it's visible by default
      }
    }
  }

  [aria-sort="ascending"] .mat-sort-header-arrow .mat-sort-header-indicator::before {
    content: "\25b4";
  }

  [aria-sort="descending"] .mat-sort-header-arrow .mat-sort-header-indicator::before {
    content: "\25be";
    top: -1em;
  }

  mat-dialog-actions {
    button {
      color: rgba(0, 89, 204, 1);
      border-radius: 17px !important;
      border: 1px solid rgba(0, 89, 204, 1) !important;
      text-align: center;
    }

    button[mat-flat-button][color="primary"] {
      border-radius: 17px !important;
      background-color: rgba(86, 140, 223, 1);
      color: #ffffff;
    }
  }

  .mat-mdc-dialog-container {
    --mdc-dialog-container-shape: 9px;

    h2.mat-mdc-dialog-title {
      color: rgba(54, 54, 54, 1);
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.3px;
    }
  }

  .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab  {
    color:#0059CC;
    background-color: white;
  }

  .mat-mdc-dialog-content {
  padding: 0 !important;

    .mat-mdc-tab-body-wrapper {
      padding: 0 24px;
    }
  }
}

h2 {
  padding-top: 8px;
  padding-bottom: 4px;
}

.cursor-pointer {
  cursor: pointer;
}

.whitespace-no-wrap {
  white-space: nowrap;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
}

.align-items-stretch {
  align-items: stretch;
}

.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: start;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.gap-4 {
  gap: 4px !important;
}

.gap-8 {
  gap: 8px !important;
}

.gap-16 {
  gap: 16px !important;
}

.gap-24 {
  gap: 24px !important;
}

.gap-32 {
  gap: 32px !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.indent {
  margin-left: 24px;
}

.text-lighter {
  font-weight: lighter;
}

.text-muted {
  color: #{ops-theme.$ops-color-inactive};
}
