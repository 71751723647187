@use 'sass:map';
@use '@angular/material' as mat;
@import '../styles/ops-palette';

@mixin color($theme) {

  $table-row-height: 30px;
  $action-cell-width: 30px;
  $action-button-size: 26px;
  $action-icon-size: 22px;

  table.mat-mdc-table.ops-table {
    background-color: $ops-color-layer-under;

    th, tr, td {
      border-style: none;
      border-width: 0;
    }

    tr.mat-mdc-header-row {
      white-space: nowrap;

      th.mat-mdc-header-cell {
        font-weight: bolder;
      }
    }

    tr.mat-mdc-row.ops-table-detail-row {
      height: 0;
    }

    tr.mat-mdc-row:not(.ops-table-detail-row) {
      white-space: nowrap;
      height: $table-row-height;

      td:last-child {

        &.ops-table-action-cell {
          display: flex;
          justify-content: right;
          align-items: center;
          padding-right: 2px;
          min-height: $table-row-height;
          width: $action-cell-width;

          .ops-table-action-button {
            color: $ops-color-layer-under;
            background-color: $ops-color-layer-under;
            padding: 3px; // without this, icon would be offset
            font-size: $action-button-size;
            height: $action-button-size;
            width: $action-button-size;
            line-height: $action-button-size;

            .ops-table-action-icon {
              font-size: $action-icon-size;
              height: $action-icon-size;
              width: $action-icon-size;
              line-height: $action-icon-size;
            }
          }
        }
      }

      &:hover {
        cursor: pointer;

        td {
          background: $ops-color-layer-over-hover !important;
        }

        td:first-child {
          background: $ops-color-layer-over-hover !important;
          -moz-border-radius: 20px 0 0 20px;
          -webkit-border-radius: 20px 0 0 20px;
          -khtml-border-radius: 20px 0 0 20px;
          border-radius: 20px 0 0 20px;
        }

        td:last-child {
          background: $ops-color-layer-over-hover !important;
          -moz-border-radius: 0 20px 20px 0;
          -webkit-border-radius: 0 20px 20px 0;
          -khtml-border-radius: 0 20px 20px 0;
          border-radius: 0 20px 20px 0;

          &.ops-table-action-cell {

            .ops-table-action-button {
              color: $ops-color-accent;
              background-color: $ops-color-layer-under;
            }
          }
        }
      }

      &.active {

        td {
          background: $ops-color-layer-over-highlight !important;
        }

        td:first-child {
          background: $ops-color-layer-over-highlight !important;
          -moz-border-radius: 20px 0 0 20px;
          -webkit-border-radius: 20px 0 0 20px;
          -khtml-border-radius: 20px 0 0 20px;
          border-radius: 20px 0 0 20px;
        }

        td:last-child {
          background: $ops-color-layer-over-highlight !important;
          -moz-border-radius: 0 20px 20px 0;
          -webkit-border-radius: 0 20px 20px 0;
          -khtml-border-radius: 0 20px 20px 0;
          border-radius: 0 20px 20px 0;
        }
      }
    }

    tr.mat-mdc-footer-row {
      white-space: nowrap;

      td.mat-mdc-footer-cell {
        color: $ops-color-accent;
        font-weight: bolder;
      }
    }
  }

  .mat-mdc-paginator-container {
    background-color: $ops-color-layer-under;
  }
}

@mixin typography($theme) {
  // font: mat.get-theme-typography($theme, headline-large, font);
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}

