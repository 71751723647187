@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  /*.shift-map-container {
    .shift__map__left__content {
      .left__panel {
        background-color: mat.m2-get-color-from-palette($primary-palette, 100);
      }
      .left__panel__round-corners {
        .left__panel__round-corners__box {
          box-shadow: 0 0 0 100px mat.m2-get-color-from-palette($primary-palette, 100);
        }
      }
    }

    .shift__right__content {
      .right__panel__round-corners {
        .right__panel__round-corners__box {
          box-shadow: 0 0 0 10px mat.m2-get-color-from-palette($primary-palette, 100);
        }
      }
      .right__panel {
        background-color: mat.m2-get-color-from-palette($primary-palette, 100);
      }
    }

    .shift__bottom__panel {
      background-color: mat.m2-get-color-from-palette($primary-palette, 100);
    }
  }*/
}

@mixin typography($theme) {
  // font: mat.get-theme-typography($theme, headline-large, font);
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}

