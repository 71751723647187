@use "@angular/material" as mat;
@use 'sass:map';
@use './layouts.scss' as layouts;
@use './layouts-content.scss' as contentLayouts;
@use './tables.scss' as tables;
@use '../app/shared/components/navigation/navigation.component-theme.scss' as navigation;
@use '../app/pages/live-map/components/live-map.component-theme.scss' as liveMap;
@use '../app/pages/shift-map/components/shift-map.component-theme' as shiftMap;
@use '../app/shared/components/side-menu/side-menu.component-theme.scss' as sideMenu;
@use '../app/shared/components/action-menu-item/action-menu-item.component-theme.scss' as actionMenuItem;
@use '../app/shared/components/card-menu-item/card-menu-item.component-theme.scss' as cardMenuItem;
@use '../app/shared/components/select-box/select-box.component-theme.scss' as selectBox;
@use '../app/shared/components/search-bar/search-bar.component-theme.scss' as searchBar;
@use '../app/pages/insights/components/shift-lookup/shift-lookup.component-theme.scss' as shiftLookup;
@use '../app/pages/insights/components/shift-report/shift-report.component-theme.scss' as shiftReport;
@use '../app/pages/live-map/components/left-panel/live-map-panel.component.scss-theme.scss' as liveMapPanel;
@use '../app/pages/live-map/components/ui-components/left-menu-item/left-menu-item.component-theme.scss' as leftMenuItem;
@use '../app/shared/components/blank-page-image/blank-page.component.scss-theme.scss' as blankPage;
@use '../app/shared/components/buttons/buttons.scss-theme' as buttons;
@use '../app/shared/components/cards/shift-detail-card/shift-detail-card.component-theme.scss' as shiftDetailCard;
@use '../app/shared/components/filter-bar/filter-bar.component.scss-theme.scss' as filterBar;
@use './live-map-filter' as liveMapFilter;
@use '../app/shared/components/map-layer-switcher/map-layer-switcher.component.scss-theme.scss' as mapLayerSwitcher;
@use '../app/shared/components/map-layer-switcher/location-history-layers-config/location-history-layers-config.component.scss-theme.scss' as locationHistoryConfig;
@use '../app/shared/components/map-layer-switcher/legend.scss' as legend;
@use '../app/shared/components/date-filter-dropdown/date-filter-dropdown.component.scss-theme.scss' as dateFilterDropdown;
@use '../app/pages/live-map/components/ui-components/right-panel-button/right-panel-button.component-theme.scss' as rightPanelButton;

@import './ops-palette.scss';
@import './ops-typography.scss';

$_rest: (
        secondary: map.get($ops-palettes, secondary),
        neutral: map.get($ops-palettes, neutral),
        neutral-variant: map.get($ops-palettes, neutral-variant),
        error: map.get($ops-palettes, error),
);
$_primary: map.merge(map.get($ops-palettes, primary), $_rest);
$_tertiary: map.merge(map.get($ops-palettes, tertiary), $_rest);

$ops-theme: mat.define-theme((
        color: (
                theme-type: light,
                primary: $_primary,
                tertiary: $_tertiary,
        ),
        typography: (
                plain-family: 'Roboto',
                brand-family: 'Roboto',
        ),
        density: (
                scale: -3,
        ),
));

html {
  @include mat.core();
  @include mat.core-theme($ops-theme);
  @include mat.all-component-themes($ops-theme);
  @include mat.all-component-typographies($ops-theme);
  @include mat.typography-hierarchy($ops-typography-config);
  // TODO switch to M3
  // @include mat.typography-hierarchy($ops-theme, $back-compat: true);

  // layout styles with applied theme
  @include layouts.theme($ops-theme);
  @include contentLayouts.theme($ops-theme);

  // standard tables override and applied theme
  @include tables.theme($ops-theme);

  // theme applied to custom components
  @include navigation.theme($ops-theme);
  @include liveMap.theme($ops-theme);
  @include shiftMap.theme($ops-theme);
  @include sideMenu.theme($ops-theme);
  @include actionMenuItem.theme($ops-theme);
  @include cardMenuItem.theme($ops-theme);
  @include selectBox.theme($ops-theme);
  @include searchBar.theme($ops-theme);
  @include shiftLookup.theme($ops-theme);
  @include shiftReport.theme($ops-theme);
  @include liveMapPanel.theme($ops-theme);
  @include leftMenuItem.theme($ops-theme);
  @include blankPage.theme($ops-theme);
  @include buttons.theme($ops-theme);
  @include shiftDetailCard.theme($ops-theme);
  @include filterBar.theme($ops-theme);
  @include liveMapFilter.theme($ops-theme);
  @include mapLayerSwitcher.theme($ops-theme);
  @include locationHistoryConfig.theme($ops-theme);
  @include legend.theme($ops-theme);
  @include dateFilterDropdown.theme($ops-theme);
  @include rightPanelButton.theme($ops-theme);

  @include mat.form-field-overrides((
    container-text-size: var(--mat-sys-body-normal-size),
    outline-color: #{$ops-color-font-secondary},
    hover-outline-color: #{darken($ops-color-font-secondary, 10%)},
    disabled-outline-color: #{lighten($ops-color-font-secondary, 10%)},
    input-text-placeholder-color: #{lighten($ops-color-font-secondary, 5%)},
    label-text-color: #{lighten($ops-color-font-secondary, 5%)},
    container-shape: 8px,
  ));

  .search-input {
    @include mat.form-field-overrides((
            container-text-size: var(--mat-sys-body-normal-size),

            hover-outline-color: #{darken($ops-color-font-secondary, 10%)},
            disabled-outline-color: #{lighten($ops-color-font-secondary, 10%)},
            input-text-placeholder-color: #{lighten($ops-color-font-secondary, 5%)},
            container-shape: 16px,
    ));
    .mdc-line-ripple {
      display: none;
    }
    .mdc-text-field--filled {
      border-radius: 16px;
      background-color: #{lighten($ops-color-font-secondary, 30%)};
    }

    &.top-card-search {
      .mdc-text-field--filled {
        border-radius: 12px 12px 0 0 !important;
      }
    }

    /*
    Find a workaround for the deprecated ::ng-deep.
    Looks like maybe with Angular 18 floatLabel="never" could be used.
    https://material.angular.io/components/form-field/api#MatFormFieldDefaultOptions
     */
    ::ng-deep {
      .mat-mdc-form-field-hint-wrapper,
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }
  }

  @include mat.select-overrides((
    trigger-text-size: var(--mat-sys-body-normal-size),
  ));

  @include mat.button-overrides((
    outline-color: #{$ops-color-accent},
    container-height: 40px,
  ));

  @include mat.icon-button-overrides((
      state-layer-size: 40px,
  ));

  @include mat.table-overrides((
      row-item-outline-color: #{lighten($ops-color-font-secondary, 30%)},
      header-headline-color: #{$ops-color-font-tertiary},
      row-item-container-height: 16px,
      //row-item-label-text-color: blue,
    //          --mat-table-row-item-container-height: 14px;
    //  --mat-table-header-headline-color: #000;
    //  --mat-table-row-item-label-text-color: #5f5f5f;
  ));

  @include mat.list-overrides((
    list-item-label-text-size: var(--mat-sys-body-normal-size),
  ));

  @include mat.card-overrides((
    title-text-size: var(--mat-sys-body-normal-size),
  ));

  @include mat.divider-overrides((
    color: #{lighten($ops-color-font-secondary, 30%)},
  ));

  @include mat.list-overrides((
    active-indicator-shape: 4px,
    list-item-one-line-container-height: 48px,
  ));

  // slide toggle
  $track-color: #{lighten($ops-color-accent, 50%)};
  $handle-color: #{lighten($ops-color-accent, 60%)};

  @include mat.slide-toggle-overrides((
    disabled-selected-icon-opacity: 0.38,
    disabled-track-opacity: 0.12,
    disabled-unselected-icon-opacity: 0.38,
    handle-height: 20px,
    handle-shape: 10px,
    handle-width: 20px,
    selected-icon-size: 18px,
    track-height: 14px,
    track-shape: 7px,
    track-width: 36px,
    unselected-icon-size: 18px,
    selected-focus-state-layer-opacity: 0.12,
    selected-hover-state-layer-opacity: 0.04,
    selected-pressed-state-layer-opacity: 0.1,
    unselected-focus-state-layer-opacity: 0.12,
    unselected-hover-state-layer-opacity: 0.04,
    unselected-pressed-state-layer-opacity: 0.1,

    disabled-selected-handle-opacity: 0.38,
    disabled-unselected-handle-opacity: 0.38,
    unselected-handle-size: 20px,
    selected-handle-size: 20px,
    pressed-handle-size: 20px,
    with-icon-handle-size: 20px,
    selected-handle-horizontal-margin: 0,
    selected-with-icon-handle-horizontal-margin: 0,
    selected-pressed-handle-horizontal-margin: 0,
    unselected-handle-horizontal-margin: 0,
    unselected-with-icon-handle-horizontal-margin: 0,
    unselected-pressed-handle-horizontal-margin: 0,
    visible-track-opacity: 1,
    hidden-track-opacity: 1,
    visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1),
    hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1),
    track-outline-width: 1px,
    track-outline-color: transparent,
    selected-track-outline-width: 1px,
    selected-track-outline-color: transparent,
    disabled-unselected-track-outline-width: 1px,
    disabled-unselected-track-outline-color: transparent,

    selected-focus-state-layer-color: #{$track-color},
    selected-handle-color: #{darken($ops-color-accent, 0%)},
    selected-hover-state-layer-color: #{$ops-color-accent},
    selected-pressed-state-layer-color: #{$ops-color-accent},
    selected-focus-handle-color: #{darken($ops-color-accent, 10%)},
    selected-hover-handle-color: #{darken($ops-color-accent, 10%)},
    selected-pressed-handle-color: #{darken($ops-color-accent, 15%)},
    selected-focus-track-color: #{$track-color},
    selected-hover-track-color: #{$track-color},
    selected-pressed-track-color: #{$track-color},
    selected-track-color: #{$track-color},
    selected-icon-color: #{darken($ops-color-accent, 40%)},
    label-text-color: $ops-color-font-primary,

    label-text-line-height: var(--mat-sys-body-normal-line-height),
    label-text-size: var(--mat-sys-body-normal-size),
    label-text-weight: var(--mat-sys-body-normal-weight),
  ));
}

// ---------------------------------------------------------
// other standard component theme overrides

// raised button style override
button.mat-mdc-raised-button {
  box-shadow: none;
  border-radius: 15px;

  &.mat-accent {
    background-color: $ops-color-accent;
    color: $ops-color-layer-under;
    border: 1px solid $ops-color-accent;
  }

  &.mat-primary {
    color: $ops-color-accent;
    background-color: $ops-color-layer-under;
    border: 1px solid #a1a1a1;
  }

  &.mat-secondary {
    color: sideMenu.$ops-color-highlight;
    background-color: $ops-color-layer-under;
    border: 1px solid #a1a1a1;
  }

  &:hover {
    box-shadow: none;
  }
}

button.mat-mdc-button {
  &.mat-accent {
    color: $ops-color-font-tertiary;
  }
}

button.mat-mdc-icon-button {
  &.mat-accent {
    @include mat.icon-button-color($ops-theme, $color-variant: primary);
  }
}

// ---------------------------------------------------------
// custom styles with applied theme

.ops-link {
  color: $ops-color-accent;
  text-decoration: none;
}

.ops-selectable-primary {
  color: $ops-color-font-secondary;

  &:hover {
    color: $ops-color-font-primary;
    background-color: $ops-color-layer-over-hover;
  }

  &.active {
    color: $ops-color-accent;
    background-color: $ops-color-layer-over-highlight;

    mat-icon {
      font-family: 'Material Icons';
      color: $ops-color-accent;
    }
  }

  &.inactive {
    color: $ops-color-inactive;
  }

  .text-primary {
    color: $ops-color-font-primary;
  }
}

.ops-selectable-secondary {
  color: $ops-color-accent;

  mat-icon {
    color: $ops-color-accent;
  }

  &.active {
    color: $ops-color-highlight;
    background-color: $ops-color-highlight-bkg;

    mat-icon {
      color: $ops-color-highlight;
    }
  }

}
