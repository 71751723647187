@use 'sass:map';
@use '@angular/material' as mat;
@import '../../../../../styles/ops-palette.scss';

@mixin theme($theme) {

  .left-panel-container {

    .group-content-wrapper {

      .group-wrapper {

        .group {

          .group-name {

            &.hidden {
              text-decoration: line-through;
            }
            &.inactive {
              color: $ops-color-inactive;
            }
          }
        }

        .group-actions {

          .active-shift-count {

            &.inactive {
              color: $ops-color-inactive;
            }
          }
        }
      }

      &:hover {
        background-color: $ops-color-menu-group-hover;
      }
    }

    .group-wrapper {
      .group {
        .group-name {
          &.strong {
            font-weight: 500;
          }
        }
      }
    }

    .route-configuration-menu-item {

      &:hover {
        background-color: $ops-color-menu-group-hover;
      }
    }
  }
}
