@use 'sass:map';
@use '@angular/material' as mat;
@import '../../../../../../styles/ops-palette';

@mixin theme($theme) {

  .ops-left-menu-item {
    .ami-subtitle-and-action {
      .ami-action-button {
        color: $ops-color-font-primary;
        background-color: $ops-color-layer-under;
      }
    }
  }
}
