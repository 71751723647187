$ops-color-layer-over: #F6F8FC;
$ops-color-layer-over-highlight: #dbe6f8;
$ops-color-layer-over-hover: #ebedf0;
$ops-color-menu-group-hover: #f0f4f9;
$ops-color-layer-control: #eaf1fb;
$ops-color-layer-under: #ffffff;
$ops-color-font-primary: #000000;
$ops-color-font-secondary: #757575; //#A0A0A0; //change required for WCAG 2.2 complicance, contrast ratio must be >= 4.5
$ops-color-inactive: #727272;
$ops-color-light-background: #F2F6FC;
$ops-color-lighter-background: #F8F9FA;

$ops-color-highlight: #FBBC05;
$ops-color-highlight-bkg: #F6F0E0;
$ops-color-font-tertiary: #3f3f3f;
$ops-color-accent: #0059CC;

$ops-color-divider: #CDCDCD;

$ops-palettes: (
        primary: (
                0: #000000,
                10: #0034e5,
                20: #0044e9,
                25: #0054ef,
                30: #0055ee,
                35: #0055ee,
                40: #0059CC,
                50: #007dbd,
                60: #0098e5,
                70: #46b3ff,
                80: #93ccff,
                90: #cce5ff,
                95: #e7f2ff,
                98: #f7f9ff,
                99: #fcfcff,
                100: #ffffff,
        ),
        secondary: (
                0: #000000,
                10: #111c29,
                20: #27313f,
                25: #323c4a,
                30: #3d4856,
                35: #485362,
                40: #545f6e,
                50: #6d7888,
                60: #8792a2,
                70: #a1acbd,
                80: #bcc7d9,
                90: #d8e3f5,
                95: #eaf1ff,
                98: #f8f9ff,
                99: #fdfcff,
                100: #ffffff,
        ),
        tertiary: (
                0: #000000,
                10: #241726,
                20: #3a2c3b,
                25: #463646,
                30: #514252,
                35: #5e4d5e,
                40: #6a596a,
                50: #847184,
                60: #9e8b9e,
                70: #baa5b9,
                80: #d6c0d4,
                90: #f3dcf1,
                95: #ffebfc,
                98: #fff7fa,
                99: #fffbff,
                100: #ffffff,
        ),
        neutral: (
                0: #000000,
                10: #1b1b1d,
                20: #303031,
                25: #3b3b3c,
                30: #474648,
                35: #535253,
                40: #5f5e5f,
                50: #787778,
                60: #929091,
                70: #acabac,
                80: #c8c6c7,
                90: #e4e2e3,
                95: #f3f0f1,
                98: #ffffff,
                99: #ffffff,
                100: #ffffff,
                4: #0e0e0f,
                6: #131314,
                12: #1f1f21,
                17: #2a2a2b,
                22: #353536,
                24: #39393a,
                87: #dcd9da,
                92: #eae7e8,
                94: #ffffff,
                96: #ffffff,
        ),
        neutral-variant: (
                0: #000000,
                10: #191c20,
                20: #2e3135,
                25: #393c40,
                30: #44474c,
                35: #505257,
                40: #5c5e63,
                50: #75777c,
                60: #8e9196,
                70: #a9abb1,
                80: #c5c6cc,
                90: #e1e2e8,
                95: #eff0f6,
                98: #f8f9ff,
                99: #fdfcff,
                100: #ffffff,
        ),
        error: (
                0: #000000,
                10: #410002,
                20: #690005,
                25: #7e0007,
                30: #93000a,
                35: #a80710,
                40: #ba1a1a,
                50: #de3730,
                60: #ff5449,
                70: #ff897d,
                80: #ffb4ab,
                90: #ffdad6,
                95: #ffedea,
                98: #fff8f7,
                99: #fffbff,
                100: #ffffff,
        ),
);
