@use 'sass:map';
@use '@angular/material' as mat;
@import '../../../../styles/ops-palette';

@mixin color($theme) {

  .ops-action-menu-item {
    .ami-subtitle-and-action {
      .ami-action-button {
        color: $ops-color-font-primary;
        background-color: $ops-color-layer-under;
      }
    }
  }
}

@mixin typography($theme) {
  // font: mat.get-theme-typography($theme, headline-large, font);
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
