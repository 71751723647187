@use 'sass:map';
@use '@angular/material' as mat;
@import '../../../../styles/ops-palette.scss';

@mixin theme($theme) {

  .active-date-range:not(:hover) {
    background-color: $ops-color-layer-over-highlight;
  }

  .active-date-range:hover {
    background-color: $ops-color-layer-over-highlight;
  }
}
