.text-white {
  color: white;
}
.border {
  border: 1px solid red;
}
.small__divider {
  background: white;
  height: 1px;
  width: 85px;
}
.text--center {
  text-align: center;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bolder {
  font-weight: 500 !important;
}
.ui-error {
  color: red;
}

// Margin
.mx-a {
  margin-left: auto;
  margin-right: auto;
}

.m0 {
  margin: 0 !important;
}
.m-10 {
  margin: 10px;
}
.m-15 {
  margin: 15px;
}
.m-16 {
  margin: 16px;
}
.m-25 {
  margin: 25px;
}

// Margin Top
.mt-0 {
  margin-top: 0 !important;
}

.mt-8 {
  margin-top: 8px !important;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-spacing {
  margin-top: 16px;
}

// Margin Bottom
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-spacing {
  margin-bottom: 16px !important;
}

// Margin Left/Right
.ml-10 {
  margin-left: 10px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-42 {
  margin-left: 42px;
}
.ml-50 {
  margin-left: 50px;
}

.mx-15 {
  margin: 0 15px;
}
.mx-25 {
  margin: 0 25px;
}

// Margin Top/Bottom
.my-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.my-15 {
  margin-top: 15px;
  margin-bottom: 15px !important;
}
.my-16 {
  margin-top: 16px;
  margin-bottom: 16px !important;
}
.my-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.my-spacing {
  margin-top: 16px;
  margin-bottom: 16px !important;
}

// Padding
.p-8 {
  padding: 8px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-16 {
  padding: 16px;
}
.p-25 {
  padding: 25px;
}

// Padding Left/Right
.pl-15 {
  padding-left: 15px;
}
.pl-16 {
  padding-left: 16px;
}
.pl-30 {
  padding-left: 30px;
}
.px-15 {
  padding: 0 15px;
}
.px-16 {
  padding: 0 16px;
}
.px-25 {
  padding: 0 25px;
}

// Padding Top/Bottom
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-16 {
  padding-top: 16px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-16 {
  padding-bottom: 16px;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

// Width/Height
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.h-100 {
  height: 100%;
}

// Min Width Height
.mw-125 {
  min-width: 125px !important;
}
.mw-100 {
  min-width: 100px !important;
}
.mw-75 {
  min-width: 75px !important;
}
.mw-50 {
  min-width: 50px !important;
}
.mw-25 {
  min-width: 25px !important;
}
