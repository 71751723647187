@use 'sass:map';
@use '@angular/material' as mat;
@import './ops-palette';

$top-panel-height: 64px;

@mixin theme($theme) {

  .basic-page-container {
    position: relative;
    width: 100%;
    height: calc(100vh - $top-panel-height);
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .left__content {
    position: absolute;
    left: 0;
    width: 295px;
    height: calc(100% - 10px);
    transition: 200ms;
    z-index: 5;
    display: flex;
    &.collapse {
      transform: translateX(-295px);
    }
    .left__panel {
      width: 295px;
    }
  }

  // left panel content
  .left-panel-container {
    height: 100%;
    width: 100%;
    position: relative;
    transition: 200ms;

    .left-menu-container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 0 5px 0 5px;

      .group-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .group {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 2px 0;
          gap: 5px;
          width: 100%;

          .tree-level-button {
            height: 18px;
            width: 18px;
            line-height: 18px;
            font-size: 18px;
          }

          .group-name {
            text-overflow: ellipsis !important;
            overflow: hidden !important;
            white-space: nowrap;
            font-weight: 400;
            cursor: text;
          }
        }

        .group-actions {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          /*.visibility-button {
            height: 18px;
            width: 18px;
            line-height: 18px;
            font-size: 18px;
            padding: 6px;
            display: none;
          }*/

          .active-shift-count {
            width: 20px;
            max-width: 20px;
            min-width: 20px;
            box-sizing: border-box;
            text-align: end;
          }
        }
      }

      .all-groups-content {
        margin-bottom: 15px;
        width: 100%;
      }

      .offline-toggle {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .drawer-layout {

    .drawer-content {
      position: relative;
      padding: 0 10px;
      box-sizing: border-box;

      .drawer-action-button {
        line-height: 40px;
        margin: 5px 0;

        .mat-icon {
          color: $ops-color-highlight;
          font-weight: 600;
        }

        .button-label {
          color: $ops-color-font-tertiary;
          font-weight: 500;
        }
      }
    }

    .drawer-action-form {
      display: flex;
      flex-direction: column;
      // https://css-tricks.com/using-css-transitions-auto-dimensions/
      transition: max-height 500ms ease-out;
      box-sizing: border-box;
      height: 0;
      max-height: 0;
      overflow: hidden;
      background-color: $ops-color-lighter-background;

      &.show-form {
        height: auto;
        max-height: 700px;
        padding: 10px 10px 10px 10px;
      }

      .form-line {
        display: flex;

        .form-icon {
          width: 36px;
          margin-top: 12px;
        }

        .form-placeholder {
          display: flex;
          align-items: center;
          line-height: 48px;
        }
      }
    }
  }

  // page content without top bar
  .page__container {
    height: calc(100vh - $top-panel-height);
    width: 100vw;
    background: $ops-color-layer-over;

    .visible__page__container {
      height: 100%;
      padding: 0 0 0 290px;
      transition: 200ms;
      &.collapse {
        padding-left: 0;
      }
      .round__container {
        height: calc(100% - 10px);
        overflow-y: auto;
        overflow-x: auto;
        background-color: $ops-color-layer-under;
        margin: 0 10px 10px 10px;
        border-radius: 10px;
        .narrow__page__container {
          height: calc(100% - 60px); // minus padding
          min-width: 300px;
          max-width: 1000px;
          margin: 0 auto;
          padding: 30px 0;
        }
        .wide__page__container {
          height: 100%; // minus padding
          margin: 0 auto;
        }
        .centered__page__container {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 200ms;
          &.collapse {
            transform: translateX(-240px);
          }
        }
        .no__content__container {
          height: 100%;
        }
      }
    }
  }
}
