@use 'sass:map';
@use '@angular/material' as mat;
@import './ops-palette.scss';

@mixin theme($theme) {

  .live-map-filter {

    .button-wrapper {
      background-color: white;
      border-radius: 10px;

      &.active {
        background-color: $ops-color-layer-over-highlight;
        color: $ops-color-accent;
        font-weight: 400;
      }
    }

    .filter-button {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      font-size: 12px;
    }
  }

  .live-map-filter-menu-wrapper {
    display: flex;
    flex-direction: column;

    .menu-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

}
