@use 'sass:map';
@use '@angular/material' as mat;
@import '../../../../styles/ops-palette.scss';

@mixin theme($theme) {
  $inactive-selectbox-color: #6D6D6D;

  .select-box-wrapper {

    .select-box {
      background-color: $ops-color-layer-control;

      .select-box-content {

        .select-box-circle {

          &.grey {
            background-color: $inactive-selectbox-color;
          }
          &.green {
            background-color: #1F8F3F;
          }
        }
      }

      .select-box-title {

        &.grey {
          color: $inactive-selectbox-color;
        }
      }

      .select-box-dropdown-icon {
        border: none;
        background-color: inherit;
        cursor: pointer;
        
        &.grey {
          color: $inactive-selectbox-color;
        }
      }
    }
  }
}
