@use 'sass:map';
@use '@angular/material' as mat;
@import '../../../../../styles/ops-palette.scss';

@mixin theme($theme) {
  .title__wrapper {
    color: $ops-color-inactive;

    .title__prefix {
      background-color: $ops-color-inactive;
    }

    .title__suffix {
      background-color: $ops-color-inactive;
    }
  }
}
