@use 'sass:map';
@use '@angular/material' as mat;
@import '../../../../../styles/ops-palette';

@mixin color($theme) {

  .shift-lookup-container {
    .header-container {
      .header-container__filters {
        .header-container__filters__bkg {
          background-color: $ops-color-layer-over;
        }
      }
    }
    .content {
      .selector-cards-divider {
        background-color: $ops-color-layer-over;
      }
    }
  }
}

@mixin typography($theme) {
  // font: mat.get-theme-typography($theme, headline-large, font);
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
