
.ops-form {
  //background-color: lightcoral; // debug
  //margin-bottom: 2px; // debug
  //padding: 2px; // debug

  .wide {
    box-sizing: border-box;
    width: 100%;
  }

  .wide.indent {
    box-sizing: border-box;
    width: calc(100% - 20px);
  }

  mat-checkbox {
    margin-left: -5px;
  }

  mat-form-field {
    //background-color: lightpink; // debug
    padding-top: 7px;
    padding-bottom: 3px;

    &.no-hint {
      div.mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }
  }

  .section-title {
    font-weight: bolder;
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > .section-buttons {
      display: flex;
      align-items: center;
    }
  }

  .field-with-top-buttons {
    //background-color: lightpink; // debug

    & > div { // div containing buttons
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 1px;
    }

    &.wide > mat-form-field {
      width: 100%;
      padding-top: 0;
    }
  }

  .field-with-right-buttons {
    //background-color: lightpink; // debug
    display: flex;

    & > div { // div containing buttons
      display: flex;
      align-items: flex-start;
      margin-top: 11px;
      margin-left: 5px;

      button {
        white-space: nowrap;
      }
    }

    &.wide > mat-form-field {
      width: 100%;
    }
  }
}

// -------------------------------------------------
// material design component overrides for forms

:root {
  // height of checkboxes and radio buttons
  --mdc-checkbox-state-layer-size: 30px;
  --mdc-radio-state-layer-size: 30px;
  --mat-tab-header-divider-height: 1px; // they are overridden somewhere to 0px transparent, couldn't find where
  --mat-tab-header-divider-color: #e0e2ec;
}

.ops-form {

  // smaller icon buttons in forms by default
  button.mat-mdc-icon-button.mat-mdc-button-base {
    width: 30px;
    height: 30px;
    padding: 0;
  }

  // height of text fields and dropdown selections
  mat-form-field.mat-mdc-form-field:not(.no-height-mod) {
    div.mat-mdc-text-field-wrapper.mdc-text-field--outlined {
      height: 35px;

      div.mdc-notched-outline__notch {
        label.mat-mdc-floating-label {
          top: 17px;
        }
        label.mat-mdc-floating-label.mdc-floating-label--float-above {
          top: 17px;
          transform: translateY(-24px) scale(0.75);
        }
      }

      div.mat-mdc-form-field-infix {
        min-height: 24px;
        padding-top: 6px;
        padding-bottom: 5px;
        input {
          padding-top: 2px;
          padding-bottom: 3px;
        }
        mat-select {
        }
      }
    }
    .mat-mdc-form-field-subscript-wrapper {
      white-space: nowrap;
    }
  }
}

.mat-mdc-form-field-icon-suffix {
  padding-right: 4px !important;
}

// smaller icon buttons in outside forms on demand
button.mat-mdc-icon-button.smaller.mat-mdc-button-base {
  width: 30px;
  height: 30px;
  padding: 0;
}
