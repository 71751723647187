@use 'sass:map';
@use '@angular/material' as mat;
@import '../../../../styles/ops-palette.scss';

@mixin theme($theme) {

  .search-form-wrapper {

    .search__box {
      background-color: $ops-color-layer-control;

      input[type='text'] {
        background-color: $ops-color-layer-control;
      }
    }
  }
}
