@use 'sass:map';
@use '@angular/material' as mat;
@import '../../../../styles/ops-palette.scss';;

@mixin color($theme) {

  .live-map-container {
    .live__left__content {
      .left__panel {
        background-color: $ops-color-layer-over;
      }
      .left__panel__round-corners {
        .left__panel__round-corners__box {
          box-shadow: 0 0 0 100px $ops-color-layer-over;
        }
      }
    }

    .live__right__content {
      .right__panel__round-corners {
        .right__panel__round-corners__box {
          box-shadow: 0 0 0 10px $ops-color-layer-over;
        }
      }
      .right__panel {
        background-color: $ops-color-layer-over;
      }
      .right__map__overlay {
        &.narrowMapOverlay {
          background-color: $ops-color-layer-over !important;
        }
      }
    }

    .live__bottom__panel {
      background-color: $ops-color-layer-over;
    }
  }
}

@mixin typography($theme) {
  // font: mat.get-theme-typography($theme, headline-large, font);
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}

