@use 'sass:map';
@use '@angular/material' as mat;
@import '../../../../styles/ops-palette.scss';

@mixin color($theme) {

  .nav-container {
    // color: $ops-color-font-secondary;
    background-color: $ops-color-layer-over;
  }
}

@mixin typography($theme) {
  // font: mat.get-theme-typography($theme, headline-large, font);
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
