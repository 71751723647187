@use 'sass:map';
@use '@angular/material' as mat;
@import '../../../../styles/ops-palette';

@mixin theme($theme) {

  .icon-text-button {
    .button-text {
      color: $ops-color-font-secondary;
      &.active {
        color: $ops-color-accent;
      }
    }
  }

  .menu__toggle__btn {
    background-color: $ops-color-layer-over !important;
  }
}
